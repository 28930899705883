<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div
    class="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
  >
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1
              class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
            >
              {{ $t("apiNotFound") }}
            </h1>
            <p class="mt-1 text-base text-gray-500">
              {{ $t("apiNotFoundErrorMsg") }}
            </p>
          </div>
          <div class="relative bg-white">
            <div class="absolute inset-0">
              <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
            </div>
            <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
              <div
                class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12"
              >
                <div class="max-w-lg mx-auto">
                  <h2
                    class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl"
                  >
                    {{ $t("getInTouch") }}
                  </h2>
                  <p class="mt-3 text-lg leading-6 text-gray-500">
                    {{ $t("getInTouchSupportMsg") }}
                  </p>
                  <dl class="mt-8 text-base text-gray-500">
                    <!-- <div>
                      <dt class="sr-only">Postal address</dt>
                      <dd>
                        <p>742 Evergreen Terrace</p>
                        <p>Springfield, OR 12345</p>
                      </dd>
                    </div> -->
                    <!-- <div class="mt-6">
                      <dt class="sr-only">Phone number</dt>
                      <dd class="flex">
                        <PhoneIcon
                          class="flex-shrink-0 h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <span class="ml-3"> +1 (555) 123-4567 </span>
                      </dd>
                    </div> -->
                    <div class="mt-3">
                      <dt class="sr-only">Email</dt>
                      <dd class="flex">
                        <MailIcon
                          class="flex-shrink-0 h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <span class="ml-3"> developers@cx-engine.com </span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div
                class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12"
              >
                <div class="max-w-lg mx-auto lg:max-w-none">
                  <form
                    action="#"
                    method="POST"
                    class="grid grid-cols-1 gap-y-6"
                  >
                    <div>
                      <label for="full-name" class="sr-only">Full name</label>
                      <input
                        type="text"
                        name="full-name"
                        id="full-name"
                        autocomplete="name"
                        class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Full name"
                      />
                    </div>
                    <div>
                      <label for="email" class="sr-only">Email</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Email"
                      />
                    </div>
                    <div>
                      <label for="phone" class="sr-only">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        autocomplete="tel"
                        class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Phone"
                      />
                    </div>
                    <div>
                      <label for="message" class="sr-only">Message</label>
                      <textarea
                        v-model="errorMsg"
                        id="message"
                        name="message"
                        rows="4"
                        class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        class="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";
const logo = localStorage.getItem("logo");
export default {
  name: "Error404",
  props: ["msg", "status"],
  components: {
    MailIcon,
    PhoneIcon,
  },
  data() {
    return {
      logo,
      errorMsg: "API not found.",
    };
  },
  methods: {
    signOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("adminToken");
      localStorage.removeItem("avatar");
      localStorage.removeItem("account");
      this.$store.dispatch("user", {});
      this.$store.dispatch("adminToken", "");
      this.$store.dispatch("token", "");
      this.$store.dispatch("userAvatar", "");
      this.$store.dispatch("account", "");
      this.$router.push("/login");
      location.reload();
    },
  },
};
</script>

<style></style>
